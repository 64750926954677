import styled from 'styled-components'
import {GridLayout} from '../../../../dashboard-v2/components/general/grid-layout.enum'
import {ColorType, MAX_SCREEN_WIDTH} from '../../../../../theme/theme'

interface ContentAreaProps {
    gridLayout: GridLayout
    tagWidget?: boolean
    centredContent?: boolean
    lessPadding?: boolean
}

function getMargin(gridLayout: GridLayout, lessPadding?: boolean): string {
    if (lessPadding) {
        return '10px 5px'
    }
    switch (gridLayout) {
        case GridLayout.ONE_COLUMN_MEDIUM:
        case GridLayout.TWO_COLUMNS_MEDIUM:
        case GridLayout.THREE_COLUMNS_MEDIUM:
            return '10px 20px'
        case GridLayout.ONE_COLUMN_SMALL:
        case GridLayout.TWO_COLUMNS_SMALL:
        case GridLayout.THREE_COLUMNS_SMALL:
            return '10px 20px'
        default:
            return '18px 30px'
    }
}

export const ContentArea = styled.div<ContentAreaProps>`
    padding: ${(props) =>
        props.tagWidget ? '5px 10px' : getMargin(props.gridLayout, props.lessPadding)};
    display: flex;
    flex-direction: column;
    ${(props) => props.centredContent && 'justify-content: center'};
    flex: 1;
    background-color: ${(props) =>
        props.tagWidget
            ? props.theme.softwareInventory.dataArea.background
            : props.theme.colors.background};
    box-shadow: 0 1px 2px #00000034;
    position: relative;
    overflow: auto;
`

interface ContentContainerMiddleProps {
    width: number
    scrollable?: boolean
    backgroundColor?: ColorType
}

interface ContentContainerBottomProps {
    width: number
}

function scrollableStyles(scrollable: boolean): string | null {
    if (!scrollable) {
        return 'z-index: 100;'
    }

    return 'overflow-y: auto; overflow-x: none; flex: 1; z-index: 0;'
}

export const ContentContainerMiddle = styled.div<ContentContainerMiddleProps>`
    background-color: ${(props) => props.backgroundColor ?? props.theme.colors.background.default};
    max-width: ${`${MAX_SCREEN_WIDTH}px`};
    width: 100%;
    align-self: center;
    ${(props) => scrollableStyles(props.scrollable || false)}
`

export const ContentContainerBottom = styled.div<ContentContainerBottomProps>`
    background-color: ${(props) => props.theme.colors.background.default};
    max-width: ${`${MAX_SCREEN_WIDTH}px`};
    width: 100%;
    align-self: center;
    z-index: 100;
`

export const Widget = styled.section`
    background-color: ${(props) => props.theme.colors.background.default};
    color: ${(props) => props.theme.colors.text.default};
    height: 100%;
    width: 100%;
`
