import {
    LineChart,
    Line,
    XAxis,
    CartesianGrid,
    ResponsiveContainer,
    YAxis,
    Tooltip,
    Legend,
} from 'recharts'
import {MetricTrend, MetricTypeToLabelLookup} from './types/metric-report-types'
import {parseISO} from 'date-fns'
import {formatDateWithoutTime} from '../../../../helpers/formatting'
import {MetricType} from '../../contexts/types/metrics-response'
import {MouseEvent, useMemo, useState} from 'react'
import {spacing} from '../../../../theme/spacing'

interface dataPoint {
    timestamp: number
    [key: string]: number
}

const LINE_COLOURS = ['#0E7A0E', '#90BF92', '#E69F00', '#D55E00']

function massageMetricsTrendChartData(metricTrends: MetricTrend[], driftRatio: number = 5 / 1000) {
    if (!metricTrends) {
        return null
    }

    let trends: MetricTrend[]
    // Only get first and last N trends if more than 2 * N.
    const N = 2
    if (metricTrends.length > N * 2) {
        trends = metricTrends.slice(0, N).concat(metricTrends.slice(-N))
    } else {
        trends = metricTrends.slice()
    }

    // get the array with largest length
    const longestTrendIdx = trends.reduce(
        (maxLenIdx, curr, i, arr) =>
            curr.trend.length > arr[maxLenIdx].trend.length ? i : maxLenIdx,
        0,
    )

    const maxVal = Math.max(...trends.map(({trend}) => Math.max(...trend.map((v) => v.change))))

    const minVal = Math.min(...trends.map(({trend}) => Math.min(...trend.map((v) => v.change))))

    const drift = (maxVal - minVal) * driftRatio

    const labels = trends.map((i) => i.metricType)

    // massage data into required format
    const data: dataPoint[] = trends[longestTrendIdx].trend.map((i) => {
        return {timestamp: parseISO(i.timestamp).getTime()}
    })

    for (let i = 0; i < trends[longestTrendIdx].trend.length; i++) {
        trends.forEach(({trend, metricType}, j) => {
            if (trend[i]) {
                const {change} = trend[i]
                const changeWithDrift = change - drift * j
                data[i][metricType] =
                    changeWithDrift > maxVal || changeWithDrift < minVal ? change : changeWithDrift
            }
        })
    }

    return {data, labels, drift, minVal, maxVal}
}

interface Props {
    metricTrends: MetricTrend[]
    driftRatio?: number
}

export function MetricsTrendChart(props: Props) {
    const {metricTrends, driftRatio} = props
    const res = useMemo(
        () => massageMetricsTrendChartData(metricTrends, driftRatio),
        [metricTrends, driftRatio],
    )
    if (!res) {
        return <></>
    }

    const {data, labels, drift, minVal, maxVal} = res

    const obj: {[label: string]: boolean | MetricType | null; hover: MetricType | null} = {
        hover: null,
    }

    const initState = labels.reduce((acc, curr) => ((acc[curr] = false), acc), obj)

    const [chartProps, setChartProps] = useState(initState)

    const handleLegendMouseEnter = (e: MouseEvent<React.ReactNode> & {dataKey: MetricType}) => {
        if (!chartProps[e.dataKey]) {
            setChartProps({...chartProps, hover: e.dataKey})
        }
    }

    const handleLegendMouseLeave = () => {
        setChartProps({...chartProps, hover: null})
    }

    return (
        <ResponsiveContainer minHeight={320} width="100%">
            <LineChart data={data} margin={{top: 20, right: 10, left: 10, bottom: 60}}>
                <Legend
                    iconType="square"
                    align="right"
                    verticalAlign="middle"
                    layout="vertical"
                    width={200}
                    formatter={(val) => {
                        const label = MetricTypeToLabelLookup[val]
                        if (label) {
                            return label
                        }

                        return 'Unknown'
                    }}
                    wrapperStyle={{
                        padding: `${spacing(1)} 0 ${spacing(1)} ${spacing(2)}`,
                        borderImage: 'linear-gradient(to bottom, #0E7A0E, #D55E00) 1 / 3px',
                        right: '0px',
                    }}
                    onMouseOver={handleLegendMouseEnter}
                    onMouseOut={handleLegendMouseLeave}
                />
                <CartesianGrid
                    stroke="#000000"
                    strokeOpacity={1}
                    strokeWidth="0.2"
                    vertical={false}
                    syncWithTicks={true}
                />
                <XAxis
                    dataKey="timestamp"
                    type="number"
                    tickLine={true}
                    domain={['dataMin', 'dataMax']}
                    tickFormatter={(value) => formatDateWithoutTime(new Date(value).toISOString())}
                    angle={-60}
                    interval={'equidistantPreserveStart'}
                    tickCount={8}
                    tickMargin={35}
                />
                <YAxis
                    label={{value: 'Change in % asset ok', angle: '-90', fill: 'black', dx: -30}}
                    axisLine={false}
                    tickLine={false}
                    tickFormatter={(value) => `${value}%`}
                    domain={([dataMin, dataMax]) => [Math.floor(dataMin), Math.ceil(dataMax)]}
                    tickCount={6}
                    interval={0}
                />
                {labels.map((label, i) => (
                    <Line
                        key={i}
                        type="linear"
                        dot={{
                            fill: LINE_COLOURS[i],
                            strokeWidth: 5,
                            opacity: Number(
                                chartProps.hover === label || !chartProps.hover ? 1 : 0.2,
                            ),
                        }}
                        dataKey={label}
                        stroke={LINE_COLOURS[i]}
                        strokeOpacity={Number(
                            chartProps.hover === label || !chartProps.hover ? 1 : 0.2,
                        )}
                        strokeWidth={2}
                        hide={chartProps[label] === true}
                    />
                ))}
                ))
                <Tooltip
                    labelFormatter={(value) => formatDateWithoutTime(new Date(value).toISOString())}
                    formatter={(value, name, payload) => {
                        const label = MetricTypeToLabelLookup[name]
                        const v = Number(value)
                        const valWithDrift =
                            v + drift * labels.findIndex((label) => payload.dataKey === label)
                        const val = v !== maxVal && v !== minVal ? valWithDrift : v
                        if (label) {
                            return [`${val.toFixed(2)}%`, label]
                        }

                        return [`${val.toFixed(2)}%`, 'Unknown']
                    }}
                />
            </LineChart>
        </ResponsiveContainer>
    )
}
