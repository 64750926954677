import styled from 'styled-components'

const COLUMN_BREAKPOINT_TABLET = '1400px'
const COLUMN_BREAKPOINT_DESKTOP = '1800px'
const WIDGET_MIN_WIDTH = '550px'

export const SectionHeader = styled.div<{gridArea: string}>`
    grid-column: span 2;
    @media (max-width: ${COLUMN_BREAKPOINT_TABLET}) {
        grid-column: span 1;
    }
    margin: 0;
    font-size: 16px;
    font-weight: 600;
`

export const PageLayout = styled.div`
    padding-inline: 40px;
    padding-block: 20px;
    border: 5px solid white;
    overflow: auto;
    display: grid;
    column-gap: 20px;
    row-gap: 20px;
    grid-template:
        'performance performance'
        'performance-widget performance-widget'
        'performance-widget performance-widget'
        'issues issues'
        'issues-widget issues-widget'
        'issues-widget issues-widget'
        'breakdown breakdown'
        'breakdown-widget breakdown-widget'
        / 1fr 1fr;

    @media (max-width: ${COLUMN_BREAKPOINT_TABLET}) {
        grid-template:
            'performance'
            'performance-widget'
            'performance-widget'
            'performance-widget'
            'performance-widget'
            'issues'
            'issues-widget'
            'issues-widget'
            'issues-widget'
            'issues-widget'
            'breakdown'
            'breakdown-widget'
            'breakdown-widget'
            / minmax(${WIDGET_MIN_WIDTH}, 1fr);
    }
`

export const WidgetContainer = styled.div<{
    colSpan?: number
    bgColor?: string
    paddingInline?: string
}>`
    background-color: ${(prop) => (prop.bgColor ? `${prop.bgColor}` : 'white')};
    padding-inline: ${(prop) => (prop.paddingInline ? prop.paddingInline : '35px')};
    padding-block: 15px;

    grid-column-end: ${(prop) => (prop.colSpan ? `span ${prop.colSpan}` : 'span 1')};

    @media (max-width: ${COLUMN_BREAKPOINT_TABLET}) {
        grid-column-end: span 1;
    }

    display: flex;
    flex-direction: column;
    gap: 10px;
`

export const WidgetHeader = styled.div`
    margin: 0;
    font-size: 16px;
    font-weight: 600;
`

export const RadarChartDimensions = {
    height: 282,
    width: 532,
}

export const VesselBreakdown = styled.div<{filterOpen: boolean}>`
    grid-area: breakdown-widget;
    display: grid;
    grid-template-columns: subgrid;
    grid-template-rows: subgrid;

    ${(props) => {
        if (props.filterOpen) {
            return `
                column-gap: 50px;
                row-gap: 20px;
                    grid-template:
                        "breakdown-widget"
                        "breakdown-widget"
        `
        } else {
            return `
                @media (max-width: ${COLUMN_BREAKPOINT_DESKTOP}) {
                    column-gap: 50px;
                    row-gap: 20px;
                    grid-template:
                        "breakdown-widget"
                        "breakdown-widget"
                }
            `
        }
    }}
`
