import {usePagedMetricsBeta} from '../../contexts/use-paged-metrics-beta'
import {NoData} from '../no-data/no-data'
import {AssetStatusWidget} from './asset-status'
import {AssetTrendChart} from './asset-trend'
import {VesselPerformanceWidget} from './vessel-performance'
import {
    MetricsIssuesBehaviourRadarChart,
    MetricsIssuesMaintenanceProtectionRadarChart,
} from './metrics-radar-charts'
import {
    PageLayout,
    SectionHeader,
    VesselBreakdown,
    WidgetContainer,
    WidgetHeader,
} from './metrics-report.styled'
import {VesselTargetPerformanceWidget} from './vessel-target-performance'
import {TagsTable} from './table/tags-table'
import {
    DetailedViewTableHeader,
    IssueBreakdownByTagsTableHeader,
    MetricsReportTableResponse,
    MetricTypeToLabelLookup,
    ServerPagedResponse,
    SortBy,
} from './types/metric-report-types'
import {TableHeaderCol} from './table/tags-table-header'
import {VesselHeatMapChart} from './vessel-heatmap'
import {MetricsTrendChart} from './metrics-trends-chart'
import {ServerPagedTable} from './table/server-paged-table'
import {useCallback, useEffect, useMemo, useState} from 'react'
import {getMetricTypeIcon, getTime} from '../data-helper'
import {RequestState} from '../../../../utils/managed-axios-request'
import {StringUtils} from '../../../../utils/Utils'
import styled from 'styled-components'
import {spacing} from '../../../../theme/spacing'
import {MetricType} from '../../contexts/types/metrics-response'
import {PillButton} from './common-components/buttons'

type FrameworkType = MetricType.PROTECTION | MetricType.MAINTENANCE | MetricType.BEHAVIOUR

const vesselBreakdownHeaders: TableHeaderCol[] = [
    {
        name: IssueBreakdownByTagsTableHeader.TAG_NAME,
        displayText: 'Tag Name',
        colType: 'string',
    },
    {
        name: IssueBreakdownByTagsTableHeader.TOTAL_ISSUE,
        displayText: 'Total Issue',
        colType: 'number',
    },
    {
        name: IssueBreakdownByTagsTableHeader.PROTECTION,
        displayText: 'Protection',
        colType: 'number',
    },
    {
        name: IssueBreakdownByTagsTableHeader.MAINTENANCE,
        displayText: 'Maintenance',
        colType: 'number',
    },
    {
        name: IssueBreakdownByTagsTableHeader.BEHAVIOUR,
        displayText: 'Behaviour',
        colType: 'number',
    },
]

const detailsTableHeaders: TableHeaderCol[] = [
    {
        name: DetailedViewTableHeader.LOCATION_NAME,
        displayText: 'Location Name',
        colType: 'string',
    },
    {
        name: DetailedViewTableHeader.ASSET_NAME,
        displayText: 'Asset Name',
        colType: 'string',
    },
    {
        name: DetailedViewTableHeader.FRAMEWORK,
        displayText: 'Framework',
        colType: 'string',
    },
    {
        name: DetailedViewTableHeader.METRIC,
        displayText: 'Metric',
        colType: 'string',
    },
    {
        name: DetailedViewTableHeader.ISSUE_DETECTED_DAYS,
        displayText: 'Issue Detected Days',
        colType: 'number',
    },
]

export function MetricsReport() {
    const {
        reportLevelSummaryData,
        showReportLevelFilter,
        reportLevelPeriod,
        useGetReportDetailsTable,
    } = usePagedMetricsBeta()

    const [reportDetailsTableState, fetchReportDetailsTable] = useGetReportDetailsTable()

    if (!reportLevelSummaryData || !reportLevelSummaryData.summary) {
        return <NoData text="There are no reports matching your filtering criteria" />
    }

    const {
        assetStatus,
        performance,
        issuesRaised,
        vesselPerformance,
        issueBreakdownByTags,
        assetTrend,
        vesselPerformanceWithMetrics,
        mostImprovedAndMostDeterioratedMetrics,
    } = reportLevelSummaryData.summary

    const [selectedFramework, setSelectedFramework] = useState<FrameworkType>(MetricType.PROTECTION)

    const [detailsTableOptions, setDetailsTableOptions] = useState<{
        sortBy: SortBy
        pageOffset: number
        rowsPerPage: number
    }>()

    const onDetailsTableLoad = useCallback(
        (sortBy: SortBy, pageOffset: number, rowsPerPage: number) => {
            setDetailsTableOptions({sortBy, pageOffset, rowsPerPage})

            let column = detailsTableHeaders[sortBy.colIdx].name
            // Workarounds for Metrics API quirk
            switch (column) {
                case DetailedViewTableHeader.LOCATION_NAME:
                    column = 'location'
                    break
                case DetailedViewTableHeader.ASSET_NAME:
                    column = 'asset'
                    break
                case DetailedViewTableHeader.ISSUE_DETECTED_DAYS:
                    column = 'timeWithControlIssue'
                    break
            }
            fetchReportDetailsTable(
                {
                    pageOffset,
                    pageSize: rowsPerPage,
                },
                {
                    column,
                    isAscending: sortBy.isAsc,
                },
                selectedFramework,
            )
        },
        [fetchReportDetailsTable],
    )

    useEffect(() => {
        if (detailsTableOptions) {
            onDetailsTableLoad(
                detailsTableOptions.sortBy,
                detailsTableOptions.pageOffset,
                detailsTableOptions.rowsPerPage,
            )
        }
    }, [selectedFramework])

    const detailsTableDataState = useMemo(
        () => createTableDataState(reportDetailsTableState),
        [reportDetailsTableState],
    )

    return (
        <PageLayout>
            <SectionHeader gridArea="performance">Summary of fleet performance</SectionHeader>
            <WidgetContainer>
                <WidgetHeader>ASSET STATUS BY FRAMEWORK CATEGORY</WidgetHeader>
                {assetStatus && <AssetStatusWidget assetStatus={assetStatus} />}
            </WidgetContainer>
            <WidgetContainer>
                <WidgetHeader>VESSEL TARGET PERFORMANCE BY FRAMEWORK CATEGORY</WidgetHeader>
                {performance && <VesselTargetPerformanceWidget performance={performance} />}
            </WidgetContainer>
            <WidgetContainer>
                <WidgetHeader>
                    BEST AND WORST VESSEL PERFORMANCE (Based on all metrics)
                </WidgetHeader>
                {vesselPerformance && (
                    <VesselPerformanceWidget vesselPerformance={vesselPerformance} />
                )}
            </WidgetContainer>
            <WidgetContainer>
                <WidgetHeader>ASSET TREND BY FRAMEWORK CATEGORY </WidgetHeader>
                {assetTrend &&
                    (assetTrend.protection || assetTrend.behaviour || assetTrend.maintenance) && (
                        <AssetTrendChart
                            assetTrend={assetTrend}
                            reportLevelPeriod={reportLevelPeriod}
                        />
                    )}
            </WidgetContainer>
            <SectionHeader gridArea="issues">
                Summary of issues and areas for improvement
            </SectionHeader>
            <WidgetContainer>
                <WidgetHeader>ISSUES RAISED IN REPORT PERIOD (Crew Behaviour)</WidgetHeader>
                {issuesRaised && <MetricsIssuesBehaviourRadarChart issuesRaised={issuesRaised} />}
            </WidgetContainer>
            <WidgetContainer>
                <WidgetHeader>ISSUES RAISED IN REPORT PERIOD (Maintenance/Protection)</WidgetHeader>
                {issuesRaised && (
                    <MetricsIssuesMaintenanceProtectionRadarChart issuesRaised={issuesRaised} />
                )}
            </WidgetContainer>
            <WidgetContainer colSpan={2}>
                <WidgetHeader>MOST IMPROVED AND MOST DETERIORATED METRICS</WidgetHeader>
                {mostImprovedAndMostDeterioratedMetrics && (
                    <MetricsTrendChart metricTrends={mostImprovedAndMostDeterioratedMetrics} />
                )}
            </WidgetContainer>
            <SectionHeader gridArea="breakdown">Vessel breakdown overview</SectionHeader>
            <VesselBreakdown filterOpen={showReportLevelFilter}>
                <WidgetContainer>
                    <WidgetHeader>ISSUE BREAKDOWN BY VESSEL AND METRICS</WidgetHeader>
                    {vesselPerformanceWithMetrics && (
                        <VesselHeatMapChart vesselPerformance={vesselPerformanceWithMetrics} />
                    )}
                </WidgetContainer>

                <WidgetContainer>
                    <WidgetHeader>ISSUE BREAKDOWN BY TAGS</WidgetHeader>
                    {issueBreakdownByTags && (
                        <TagsTable data={issueBreakdownByTags} headers={vesselBreakdownHeaders} />
                    )}
                </WidgetContainer>
            </VesselBreakdown>

            <SectionHeader gridArea="detail">Detailed view</SectionHeader>

            <WidgetContainer colSpan={2} bgColor="inherit" paddingInline="0">
                <div>
                    {[MetricType.PROTECTION, MetricType.MAINTENANCE, MetricType.BEHAVIOUR].map(
                        (framework) => (
                            <MarginedPillButton
                                selected={framework === selectedFramework}
                                onClick={() => setSelectedFramework(framework as FrameworkType)}
                                key={framework}
                            >
                                {StringUtils.capitaliseString(framework)}
                            </MarginedPillButton>
                        ),
                    )}
                </div>
                <ServerPagedTable
                    headers={detailsTableHeaders}
                    state={detailsTableDataState}
                    onLoad={onDetailsTableLoad}
                />
            </WidgetContainer>
        </PageLayout>
    )
}

function createTableDataState(
    reportLevelDetailsTableState: RequestState<MetricsReportTableResponse>,
): RequestState<ServerPagedResponse<object>> | undefined {
    if (!reportLevelDetailsTableState) {
        return undefined
    }
    if (!reportLevelDetailsTableState.result) {
        return reportLevelDetailsTableState
    }

    return {
        ...reportLevelDetailsTableState,
        result: {
            ...reportLevelDetailsTableState.result,
            data: reportLevelDetailsTableState.result.data.map((d) => {
                return {
                    ...d,
                    issueDetectedDays: getTime(d.timeWithControlIssue),
                    metric:
                        MetricTypeToLabelLookup[d.metric] ||
                        StringUtils.capitaliseString(d.metric.replace(/([A-Z])/g, ' $1')),
                    framework: (
                        <DivAlignLeft>
                            <img
                                src={getMetricTypeIcon(d.framework)}
                                style={{width: '35px', height: '35px', marginRight: '5px'}}
                            />
                            {StringUtils.capitaliseString(d.framework)}
                        </DivAlignLeft>
                    ),
                }
            }),
        },
    }
}

const DivAlignLeft = styled.div`
    text-align: left;
`

const MarginedPillButton = styled(PillButton)`
    margin-right: ${spacing(4)};
    margin-bottom: ${spacing(2)};
`
