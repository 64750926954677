import {ActionType} from './action-type'
import * as Actions from './actions'
import {
    AllCustomerBenchmarksData,
    MetricContextDataMap,
    MetricSummmaryMap,
} from '../types/metrics-summary'
import {MetricModalDetails, MetricType, MetricsResponse} from '../types/metrics-response'
import {
    TimestampFilterType,
    ViewScreenLevelType,
} from '../../../../store/state/metrics-filter-beta/state'
import {HighlightedFilerValueType} from '../types/highlighted-filter-type'
import {GuidType} from '../../../../values/generic-type-defintions'
import {DetailExpandedType} from '../types/modal-types'
import {MetricsReportSummaryResponse} from '../../components/metrics-report/types/metric-report-types'

export function requestInitialPageData(): Actions.RequestInitialPageDataAction {
    return {type: ActionType.REQUEST_INITIAL_PAGE_DATA_ACTION}
}

export function requestPageData(requestedPage: number): Actions.RequestPageDataAction {
    return {type: ActionType.REQUEST_PAGE_DATA_ACTION, payload: requestedPage}
}

export function receivedRequestedTablePageData(
    data: MetricsResponse,
    totalNumberOfItems: number,
    totalNumberOfPages: number,
): Actions.ReceivedRequestedTablePageDataAction {
    return {
        type: ActionType.RECEIVED_REQUESTED_TABLE_PAGE_DATA_ACTION,
        payload: {
            data,
            totalNumberOfItems,
            totalNumberOfPages,
        },
    }
}

export function receivedRequestedPageData(
    dataSummary: MetricSummmaryMap,
    metricTypesArray: MetricType[],
): Actions.ReceivedRequestedPageDataAction {
    return {
        type: ActionType.RECEIVED_REQUESTED_SUMMARY_DATA_ACTION,
        payload: {dataSummary, metricTypesArray},
    }
}

export function displayFilterBar(displayFilterBar: boolean): Actions.DisplayFilterBarAction {
    return {
        type: ActionType.DISPLAY_FILTER_BAR,
        payload: displayFilterBar,
    }
}

export function setFilter(): Actions.SetFilterAction {
    return {type: ActionType.SET_FILTER}
}

export function setSavedFilterDropdown(value: boolean): Actions.SetActiveSavedFilterDropdownAction {
    return {
        type: ActionType.SET_ACTIVE_SAVED_FILTER_DROPDOWN,
        payload: value,
    }
}

export function setPreviousPage(
    previousPage: ViewScreenLevelType,
    previousMetricTypesSelection: MetricType[] | undefined,
): Actions.SetPreviousPageAction {
    return {
        type: ActionType.SET_PREVIOUS_PAGE,
        payload: {previousPage, previousMetricTypesSelection},
    }
}

export function setHighlightedFilterValue(
    value: HighlightedFilerValueType,
): Actions.SetHighlightedFilterValueAction {
    return {
        type: ActionType.SET_HIGHLIGHTED_FILTER_VALUE,
        payload: value,
    }
}

export function requestModalData(
    metric: MetricType,
    locationId: GuidType,
    index: number,
): Actions.RequestModalDataAction {
    return {type: ActionType.REQUEST_MODAL_DATA_ACTION, payload: {metric, locationId, index}}
}

export function receivedRequestedModalData(
    data: MetricModalDetails,
): Actions.ReceivedModalDataAction {
    return {
        type: ActionType.RECEIVED_MODAL_DATA_ACTION,
        payload: data,
    }
}

export function toggleModalDetailsExpanded(
    detailExpanded: DetailExpandedType,
): Actions.ToggleDetailExpandedAction {
    return {
        type: ActionType.TOGGLE_MODAL_DETAILS_EXPANDDED,
        payload: detailExpanded,
    }
}

export function closeMetricsDetailsModal(): Actions.CloseMetricsDetailsModalAction {
    return {
        type: ActionType.CLOSE_METRICS_DETAILS_MODAL,
    }
}

export function setWholeFleetBenchmark(
    data: MetricSummmaryMap,
): Actions.SetWholeFleetBenchmarkAction {
    return {
        type: ActionType.SET_WHOLE_FLEET_BENCHMARK_VALUE,
        payload: data,
    }
}

export function setAllCustomerBenchmarkBenchmark(
    data: AllCustomerBenchmarksData[],
): Actions.SetAllCustomerBenchmarkAction {
    return {
        type: ActionType.SET_ALL_CUSTOMER_BENCHMARK_VALUE,
        payload: data,
    }
}

export function requestMetricContextData(): Actions.RequestMetricContextDataAction {
    return {type: ActionType.REQUEST_METRIC_CONTEXT_DATA_ACTION}
}

export function receivedRequestedMetricContextData(
    data: MetricContextDataMap,
): Actions.ReceiveRequestedMetricContextDataAction {
    return {
        type: ActionType.RECEIVED_REQUESTED_METRIC_CONTEXT_DATA_ACTION,
        payload: data,
    }
}

export function receivedReportLevelData(
    data: MetricsReportSummaryResponse,
): Actions.ReceivedReportLevelDataAction {
    return {
        type: ActionType.RECEIVED_REPORT_LEVEL_DATA_ACTION,
        payload: data,
    }
}

export function toggleReportLevelFilter(value: boolean): Actions.ToggleReportLevelFilterAction {
    return {
        type: ActionType.TOGGLE_REPORT_LEVEL_FILTER,
        payload: value,
    }
}

export function setReportLevelPeriod(
    value: TimestampFilterType,
): Actions.SetReportLevelPeriodAction {
    return {
        type: ActionType.SET_REPORT_LEVEL_PERIOD,
        payload: value,
    }
}
