import {useTheme} from 'styled-components'
import {useEffect, useMemo, useState} from 'react'
import * as Styled from './tags-table.styled'
import {TableHeader, TableHeaderCol} from './table-header'
import {SortBy} from '../types/metric-report-types'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {getGridLayout} from '../../../../dashboard-v2/components/general/helpers'
import {RequestState} from '../../../../../utils/managed-axios-request'
import {ServerPagedResponse, TableColValue} from './server-paged-table.types'
import {TableContent} from './table-body'
import {
    getPagesToShow,
    getPagingButtonsToShow,
} from '../../../../../components/paging/basic/paging-helpers'
import {BasicPaging} from '../../../../../components/paging/basic/basic-paging'
import {Footer} from '../../../../../components/table-view-components/footer.styled'
import {RecordSetForPagination} from '../../../../../components/paging/basic/record-set-for-pagination'
import {LoaderOverlay} from './loader-overlay'
import LoadingState from '../../../../../values/loading-state-enum'

/**
 * ServerPagedTable and all its descendant, including data actions, is based on the
 * ServerPagedTable component in `dashboard-v2` folder. Certain functionalities are
 * not kept to limit its scope.
 *
 * The first column of data array is preset to be tag name, so make sure that
 * it is so when the data is passed in.
 */
export function ServerPagedTable<T extends object>({
    headers,
    state,
    onLoad,
    rowsPerPage = 10,
}: {
    headers: TableHeaderCol[]
    state: RequestState<ServerPagedResponse<T>> | undefined
    onLoad: (sortBy: SortBy, pageOffset: number, rowsPerPage: number) => void
    rowsPerPage?: number
}): JSX.Element {
    const {width} = useDimensions()
    const theme = useTheme()
    const gridLayout = getGridLayout(width)
    const [sortBy, setSortBy] = useState<SortBy>({
        isAsc: true,
        colIdx: 0,
    })
    const [selectedPage, setSelectedPage] = useState<number>(0)
    const [paginationState, setPaginationState] = useState<
        | {
              rows: TableColValue[][]
              startingIndicator: number
              endIndicator: number
              totalNumberOfItems: number
              totalNumberOfPages: number
          }
        | undefined
    >()

    const pageOffset = useMemo(() => {
        return selectedPage * rowsPerPage
    }, [selectedPage, rowsPerPage])

    useEffect(() => {
        onLoad(sortBy, pageOffset, rowsPerPage)
    }, [sortBy, pageOffset, rowsPerPage])

    useEffect(() => {
        if (!state?.result?.data) {
            return
        }
        if (state.error) {
            setPaginationState(undefined)
            return
        }
        if (state.result.data.length === 0 && state.result.totalNumberOfPages > 0) {
            // If there is no data on the current page, go back to the last page
            setSelectedPage(state.result.totalNumberOfPages - 1)
            return
        }
        setPaginationState({
            rows: initRows(state.result.data || [], headers),
            startingIndicator: pageOffset + 1,
            endIndicator: pageOffset + state.result.data.length,
            totalNumberOfItems: state.result.totalNumberOfItems,
            totalNumberOfPages: state.result.totalNumberOfPages,
        })
    }, [state])

    return (
        <Styled.Widget>
            <Styled.ContentArea gridLayout={gridLayout} tagWidget>
                <Styled.ContentContainerMiddle
                    width={width}
                    backgroundColor={theme.softwareInventory.dataArea.background}
                >
                    <TableHeader sortBy={sortBy} setSortBy={setSortBy} headers={headers} />
                </Styled.ContentContainerMiddle>
                <Styled.ContentContainerMiddle
                    width={width}
                    scrollable
                    backgroundColor={theme.softwareInventory.dataArea.background}
                >
                    {paginationState && <TableContent rows={paginationState.rows} />}
                    <LoaderOverlay
                        isLoading={state?.loadingState === LoadingState.RequestingData}
                    />
                </Styled.ContentContainerMiddle>
                <Styled.ContentContainerBottom width={width}>
                    {paginationState && (
                        <Footer width={width} id="footer-row">
                            <BasicPaging
                                selectedPage={selectedPage}
                                totalNumberOfPages={paginationState.totalNumberOfPages}
                                selectPage={(v) => setSelectedPage(v ?? 0)}
                                pagesToShow={getPagesToShow(width)}
                                pageSize={rowsPerPage}
                                showButtons={getPagingButtonsToShow(
                                    width,
                                    paginationState.totalNumberOfPages,
                                )}
                            />
                            <RecordSetForPagination {...paginationState} />
                        </Footer>
                    )}
                </Styled.ContentContainerBottom>
            </Styled.ContentArea>
        </Styled.Widget>
    )
}

function initRows(data: object[], headers: TableHeaderCol[]): TableColValue[][] {
    const rows: TableColValue[][] = []

    data.forEach((d) => {
        const row: TableColValue[] = []
        for (let i = 0; i < headers.length; i++) {
            for (const [k, v] of Object.entries(d)) {
                if (headers[i].name === k) {
                    if (typeof v === 'object') {
                        row.push(v)
                    } else {
                        row.push(v.toString())
                    }
                }
            }
        }
        rows.push(row)
    })
    return rows
}
