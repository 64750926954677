import {MetricTableHeaderForUI} from '../../../contexts/types/metrics-response'
import {usePagedMetricsBeta} from '../../../contexts/use-paged-metrics-beta'
import {FixedColumn} from './fixed-column'
import {TableHeaderGrid} from './_styled/metrics-table.styled'
import {SortableColumn} from './sortable-column'

export function TableHeaderRow(): JSX.Element {
    const {dataTablesHeader, idForMetricDetailsModal} = usePagedMetricsBeta()
    return (
        <TableHeaderGrid
            showReducedColumnNo={idForMetricDetailsModal !== null}
            id="table-header-grid"
        >
            {dataTablesHeader.map((column, index) => (
                <GetColumnType
                    key={`column-${column.columnName}-${index}`}
                    column={column}
                    index={index}
                />
            ))}
        </TableHeaderGrid>
    )
}
interface GetColumnTypeProps {
    column: MetricTableHeaderForUI
    index: number
}
function GetColumnType({column, index}: GetColumnTypeProps): JSX.Element {
    switch (column.columnType) {
        case 'sortable':
            return (
                <SortableColumn
                    gridColumn={index + 2}
                    text={column.columnText == 'Asset' ? 'Asset identity' : column.columnText}
                    colName={column.columnName}
                />
            )
        case 'fixed':
        default:
            return <FixedColumn gridColumn={index + 2} text={column.columnText} />
    }
}
