import useTypedSelector from '../../../../hooks/use-typed-selector'
import {
    getAnalysisPeriodDisplayName,
    getAnalysisTypeDisplayName,
} from '../../../../pages/metrics-beta/components/data-helper'
import {allMainMetricsTypeSelector} from '../../../../store/state/metric-types/selectors'
import {
    AnalysisType,
    MetricsBetaFilter,
    MetricsSortType,
} from '../../../../store/state/metrics-filter-beta/state'
import {StringUtils} from '../../../../utils/Utils'
import {Details, DetailsWrapper} from '../_styled/detail-tooltip.styled'

interface DetailFilterProps {
    criteria: MetricsBetaFilter
    tooltip?: boolean
}

function getMappedName(criteria: MetricsBetaFilter): string {
    switch (criteria.orderBy.column) {
        case MetricsSortType.ASSET_NAME:
            return 'Asset identity'
        default:
            return criteria.orderBy.column
    }
}

export function DetailFilterMetricsBeta({criteria}: DetailFilterProps): JSX.Element {
    const allMainMetricsType = useTypedSelector(allMainMetricsTypeSelector)
    const formattedMetricType = criteria.metricTypes ?? allMainMetricsType

    return (
        <DetailsWrapper>
            {criteria.metricTypes && (
                <Details id="formattedMetricType">
                    • Metrics:
                    {StringUtils.capitaliseString(
                        formattedMetricType.join(',').replace(/([A-Z])/g, ' $1'),
                    )}
                </Details>
            )}
            <Details id="analysisTypes">
                • Analysis Type: {getAnalysisTypeDisplayName(criteria.analysisTypes)}
            </Details>
            <Details id="periodForTarget">
                • Period: {getAnalysisPeriodDisplayName(criteria.selectedAnalysisPeriod)}
            </Details>
            {criteria.selectedViewScreenType === 'Table' && (
                <Details id="orderBy">
                    • Order By: {getMappedName(criteria)} (
                    {criteria.orderBy.isAscending ? 'Ascending' : 'Descending'})
                </Details>
            )}
            {criteria.analysisTypes === AnalysisType.SCORECARD && (
                <Details id="onlyAssetsWithIssues">
                    •{criteria.onlyAssetsWithIssues ? 'Show assets with issues' : 'Show all assets'}
                </Details>
            )}
            {criteria.assetValues != undefined && (
                <Details id="assetValues-filter">
                    • Asset values:
                    {StringUtils.capitaliseString(
                        criteria.assetValues.join(',').replace(/([A-Z])/g, ' $1'),
                    )}
                </Details>
            )}
        </DetailsWrapper>
    )
}
