export enum ActionType {
    SET_SELECTED_PAGE = '[PAGED METRICS BETA CONTEXT] SET SELECTED PAGE',
    REQUEST_INITIAL_PAGE_DATA_ACTION = '[PAGED METRICS BETA CONTEXT] REQUEST INITIAL PAGE DATA ACTION',
    REQUEST_PAGE_DATA_ACTION = '[PAGED METRICS BETA CONTEXT] REQUEST PAGE DATA ACTION',
    RECEIVED_REQUESTED_TABLE_PAGE_DATA_ACTION = '[PAGED METRICS BETA CONTEXT] RECEIVED REQUESTED TABLE PAGE DATA ACTION',
    RECEIVED_REQUESTED_SUMMARY_DATA_ACTION = '[PAGED METRICS BETA CONTEXT] RECEIVED REQUESTED SUMMARY DATA ACTION',
    DISPLAY_FILTER_BAR = '[PAGED METRICS BETA CONTEXT] DISPLAY FILTER BAR',
    SET_FILTER = '[PAGED METRICS BETA CONTEXT] SET FILTER',
    SET_SUMMARY = '[PAGED METRICS BETA CONTEXT] SET SUMMARY',
    SET_ACTIVE_SAVED_FILTER_DROPDOWN = '[PAGED METRICS BETA CONTEXT] SET ACTIVE SAVED FILTER DROPDOWN',
    SET_PREVIOUS_PAGE = '[PAGED METRICS BETA CONTEXT] SET PREVIOUS PAGE',
    SET_HIGHLIGHTED_FILTER_VALUE = '[PAGED METRICS BETA CONTEXT] SET HIGHLIGHTED FILTER VALUE',
    REQUEST_MODAL_DATA_ACTION = '[PAGED METRICS BETA CONTEXT] REQUEST MODAL DATA ACTION',
    RECEIVED_MODAL_DATA_ACTION = '[PAGED METRICS BETA CONTEXT] RECEIVED REQUESTED MODAL DATA ACTION',
    TOGGLE_MODAL_DETAILS_EXPANDDED = '[PAGED METRICS BETA CONTEXT] TOGGLE MODAL DETAILS EXPANDDED',
    CLOSE_METRICS_DETAILS_MODAL = '[PAGED METRICS BETA CONTEXT] CLOSE METRICS DETAILS MODAL',
    SET_WHOLE_FLEET_BENCHMARK_VALUE = '[PAGED METRICS BETA CONTEXT] SET WHOLE FLEET BENCHMARK VALUE',
    SET_ALL_CUSTOMER_BENCHMARK_VALUE = '[PAGED METRICS BETA CONTEXT] SET ALL CUSTOMER BENCHMARK VALUE',
    REQUEST_METRIC_CONTEXT_DATA_ACTION = '[PAGED METRICS BETA CONTEXT] REQUEST METRIC CONTEXT DATA ACTION',
    RECEIVED_REQUESTED_METRIC_CONTEXT_DATA_ACTION = '[PAGED METRICS BETA CONTEXT] RECEIVED REQUESTED METRIC CONTEXT DATA ACTION',

    // Report view level related
    RECEIVED_REPORT_LEVEL_DATA_ACTION = '[PAGED METRICS BETA CONTEXT] RECEIVED REPORT LEVEL DATA ACTION',
    SET_ISSUE_BREAKDOWN_TABLE_SORT_COLUMN = '[PAGED METRICS BETA CONTEXT] SET ISSUE BREAKDOWN TABLE SORT COLUMN',
    TOGGLE_REPORT_LEVEL_FILTER = '[PAGED METRICS BETA CONTEXT] TOGGLE REPORT LEVEL FILTER',
    SET_REPORT_LEVEL_PERIOD = '[PAGED METRICS BETA CONTEXT] SET REPORT LEVEL PERIOD',
}
