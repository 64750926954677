import {AxiosRequestConfig, AxiosResponse} from 'axios'
import LoadingState from '../values/loading-state-enum'
import {useCallback, useState} from 'react'

/**
 * The state of the request.
 */
export interface RequestState<T> {
    loadingState: LoadingState
    error?: Error
    result?: T
    promise?: Promise<T>
    abort: (reason: string) => void
}

/**
 * Creates a managed Axios request.
 *
 * @example createManagedAxiosRequest(
 *    (config) => axios.get('/api/v1/data', config),
 *    (state) => console.log(state)
 * )
 * @template T - The type of the response data.
 * @param {function} fnCallAxios - The function that performs the Axios request.
 * @param {function} onUpdateState - The callback function to handle the request state.
 * @returns {void}
 */
export function createManagedAxiosRequest<T>(
    fnCallAxios: (config: AxiosRequestConfig) => Promise<AxiosResponse<T>>,
    onUpdateState: (state: RequestState<T>) => void,
): void {
    const abortController = new AbortController()
    const requestState: RequestState<T> = {
        loadingState: LoadingState.RequestingData,
        abort: (reason) => abortController.abort(reason),
    }

    requestState.promise = fnCallAxios({signal: abortController.signal})
        .then((response) => {
            onUpdateState({
                ...requestState,
                result: response.data,
                loadingState: LoadingState.Loaded,
            })
            return response.data
        })
        .catch((error) => {
            onUpdateState({
                ...requestState,
                error,
                loadingState: LoadingState.Errored,
            })
            throw error
        })

    onUpdateState(requestState)
}

/**
 * Custom hook for managing Axios requests.
 *
 * @example const [state, fetch] = useManagedAxiosRequest(
 *   (id: string) => (config) => axios.get(`/api/v1/data/${id}`, config)
 * )
 * @template T - The type of the response data.
 * @template Args - The type of the arguments for the Axios call generator function.
 * @param {(...args: Args) => (config: AxiosRequestConfig) => Promise<AxiosResponse<T>>} fnAxiosCallGenerator - The function that generates the Axios call.
 * @returns {[RequestState<T>, (...args: Args) => void]} - An array containing the request state and the fetch function.
 */
export function useManagedAxiosRequest<T, Args extends unknown[]>(
    fnAxiosCallGenerator: (
        ...args: Args
    ) => (config: AxiosRequestConfig) => Promise<AxiosResponse<T>>,
): [RequestState<T>, (...args: Args) => void] {
    const [state, setState] = useState<RequestState<T>>({
        loadingState: LoadingState.NotPopulated,
        abort: () => {},
    })

    const fetch = useCallback(
        (...args: Args) => {
            createManagedAxiosRequest(fnAxiosCallGenerator(...args), setState)
        },
        [fnAxiosCallGenerator],
    )

    return [state, fetch]
}
