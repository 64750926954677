import {ActionType} from './action-type'
import * as Actions from './actions'
import produce from 'immer'
import {LoggingOutAction} from '../../../../store/state/session-data/actions'
import SessionActionType from '../../../../store/state/session-data/action-type'
import LoadingState from '../../../../values/loading-state-enum'
import {PagedMetricsBetaState} from '../types/paged-metrics-state'
import {DEFAULT_PAGED_METRICS_BETA_STATE} from '../types/default-paged-metrics-state'
import {MetricSummary} from '../types/metrics-summary'
import {MetricType, MetricsResponse} from '../types/metrics-response'
import {isEqual} from 'lodash'
import {ProcessingStatus} from '../types/modal-types'

export const pagedMetricsReducer = produce(
    (draft: PagedMetricsBetaState, action: Actions.AllActions | LoggingOutAction) => {
        switch (action.type) {
            case ActionType.REQUEST_INITIAL_PAGE_DATA_ACTION:
                draft.loadingInitialDataState = LoadingState.RequestingData
                break

            case ActionType.RECEIVED_REQUESTED_SUMMARY_DATA_ACTION:
                draft.loadingInitialDataState = LoadingState.Loaded
                draft.loadingFilteredDataState = LoadingState.Loaded
                draft.metricTypesArray = action.payload.metricTypesArray

                if (!draft.dataMetricsMap) {
                    draft.dataMetricsMap = new Map<MetricType, MetricSummary>()
                }

                if (!isEqual(draft.dataMetricsMap, action.payload.dataSummary)) {
                    draft.dataMetricsMap = action.payload.dataSummary
                }

                break

            case ActionType.DISPLAY_FILTER_BAR:
                draft.showFilterBar = action.payload
                break
            case ActionType.SET_ACTIVE_SAVED_FILTER_DROPDOWN:
                draft.isInactive = action.payload
                break
            case ActionType.SET_FILTER:
                draft.loadingFilteredDataState = LoadingState.RequestingData
                draft.selectedPage = 0
                draft.pagedDataMetricsTableMap = new Map<number, MetricsResponse>()
                draft.totalNumberOfMetrics = undefined
                draft.totalNumberOfPages = undefined
                break
            case ActionType.REQUEST_PAGE_DATA_ACTION:
                draft.loadingFilteredDataState = LoadingState.RequestingData
                draft.selectedPage = action.payload
                break
            case ActionType.RECEIVED_REQUESTED_TABLE_PAGE_DATA_ACTION:
                draft.loadingInitialDataState = LoadingState.Loaded
                draft.loadingFilteredDataState = LoadingState.Loaded
                draft.totalNumberOfMetrics = action.payload.totalNumberOfItems
                draft.totalNumberOfPages = action.payload.totalNumberOfPages

                if (!draft.pagedDataMetricsTableMap) {
                    draft.pagedDataMetricsTableMap = new Map<number, MetricsResponse>()
                }
                if (
                    !draft.pagedDataMetricsTableMap.has(draft.selectedPage) ||
                    !isEqual(
                        draft.pagedDataMetricsTableMap.get(draft.selectedPage),
                        action.payload.data,
                    )
                ) {
                    draft.pagedDataMetricsTableMap.set(draft.selectedPage, action.payload.data)
                }
                break
            case ActionType.SET_PREVIOUS_PAGE:
                draft.previousPage = action.payload.previousPage
                draft.previousMetricTypesSelection = action.payload.previousMetricTypesSelection
                break
            case ActionType.SET_HIGHLIGHTED_FILTER_VALUE:
                draft.highlightedFilerValue = action.payload
                break
            case ActionType.REQUEST_MODAL_DATA_ACTION:
                draft.idForMetricDetailsModal = action.payload
                draft.processingMetricDetailsModal = ProcessingStatus.PROCESSING
                break
            case ActionType.RECEIVED_MODAL_DATA_ACTION:
                draft.processingMetricDetailsModal = ProcessingStatus.PROCESSED
                draft.findMetricDetailsForModal = action.payload
                break
            case ActionType.CLOSE_METRICS_DETAILS_MODAL:
                draft.idForMetricDetailsModal = null
                draft.findMetricDetailsForModal = undefined
                draft.processingMetricDetailsModal = ProcessingStatus.NOT_PROCESSING
                break
            case ActionType.TOGGLE_MODAL_DETAILS_EXPANDDED:
                if (!isEqual(draft.modalDetailsExpanded, action.payload)) {
                    draft.modalDetailsExpanded = action.payload
                }
                break
            case ActionType.SET_WHOLE_FLEET_BENCHMARK_VALUE:
                if (!draft.wholeFleetBenchmarkMap) {
                    draft.wholeFleetBenchmarkMap = new Map<MetricType, MetricSummary>()
                }

                if (!isEqual(draft.wholeFleetBenchmarkMap, action.payload)) {
                    draft.wholeFleetBenchmarkMap = action.payload
                }
                break
            case ActionType.SET_ALL_CUSTOMER_BENCHMARK_VALUE:
                draft.allCustomerBenchmarks = action.payload
                break
            case ActionType.REQUEST_METRIC_CONTEXT_DATA_ACTION:
                draft.loadingMetricContextData = LoadingState.RequestingData
                break
            case ActionType.RECEIVED_REQUESTED_METRIC_CONTEXT_DATA_ACTION:
                draft.loadingMetricContextData = LoadingState.Loaded
                draft.metricContextData = action.payload
                break
            case SessionActionType.LOGGING_OUT:
                draft = DEFAULT_PAGED_METRICS_BETA_STATE
                break

            case ActionType.RECEIVED_REPORT_LEVEL_DATA_ACTION:
                draft.loadingInitialDataState = LoadingState.Loaded
                // This is to make the page stop showing a loading spinner
                draft.loadingFilteredDataState = LoadingState.Loaded
                // compute totalIssue for issueBreakdown
                if (action.payload.summary && action.payload.summary.issueBreakdownByTags) {
                    action.payload.summary.issueBreakdownByTags.forEach(
                        (i) => (i.totalIssue = i.behaviour + i.maintenance + i.protection),
                    )
                }
                draft.reportLevelSummaryData = action.payload
                break
            case ActionType.TOGGLE_REPORT_LEVEL_FILTER:
                draft.showReportLevelFilter = action.payload
                break
            case ActionType.SET_REPORT_LEVEL_PERIOD:
                draft.reportLevelPeriod = action.payload
                break
            /* istanbul ignore next */
            default:
                break
        }
        return draft
    },
)
