import styled from 'styled-components'
import * as AnalysisSelectionStyle from '../../../../pages/metrics-beta/components/filter-bar/analysis-selection-filter/analysis-selection.styled'
import {TimestampFilterType} from '../../../../store/state/metrics-filter-beta/state'
import {getAnalysisPeriodDisplayName} from '../data-helper'
import {usePagedMetricsBeta} from '../../contexts/use-paged-metrics-beta'
import * as Style1 from '../filter-bar/header/filter-bar-header.styled'
import * as Style2 from '../filter-bar/filter-bar.styled'
import * as Style3 from '../filter-bar/shared/expand-filter-details-button/expand-filter-details-button.styled'
import {XCircle} from 'react-feather'
import {useState} from 'react'
import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import {VesselFilters} from '../../../../components/vessel-filters/vessel-filters'
import {PageType} from '../../../../components/vessel-filters/data-helper'
import {spacing} from '../../../../theme/spacing'

const Filter = styled.div`
    background: white;
    align-self: start;
    overflow: auto;
    margin-block: 30px;
    min-width: 320px;
    color: ${(props) => props.theme.colors.text.default};
`

const HeaderSection = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: ${spacing(2)};
`

interface SectionExpand {
    expandPeriod: boolean
}

export function SidebarFilter() {
    const {
        setReportLevelPeriod,
        reportLevelPeriod,
        fetchReportLevelSummaryData,
        toggleReportLevelFilter,
    } = usePagedMetricsBeta()

    const handleOnPeriodChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const period = event.target.value as TimestampFilterType
        setReportLevelPeriod(period)
        fetchReportLevelSummaryData(period)
    }

    const periodOptions = [
        TimestampFilterType.LAST_7_DAYS,
        TimestampFilterType.ONE_FORTNIGHT,
        TimestampFilterType.LAST_30_DAYS,
        TimestampFilterType.ONE_QUARTER,
        TimestampFilterType.ONE_YEAR,
    ]

    const [state, setState] = useState<SectionExpand>({expandPeriod: true})

    function handleSectionToggle(sectionKey: keyof SectionExpand) {
        setState({...state, [sectionKey]: !state[sectionKey]})
    }

    return (
        <Filter>
            <Style1.SectionHeader>
                <Style1.SectionTitle>Metrics Report Filter</Style1.SectionTitle>

                <div
                    style={{cursor: 'pointer'}}
                    onClick={() => {
                        toggleReportLevelFilter(false)
                    }}
                >
                    <XCircle color="white" fill="blue" />
                </div>
            </Style1.SectionHeader>
            <Style2.SectionContent isInactive={false}>
                <HeaderSection>
                    <Style3.FilterHeadingRow>Period selection</Style3.FilterHeadingRow>
                    <Style3.Button
                        onClick={() => {
                            handleSectionToggle('expandPeriod')
                        }}
                    >
                        <Style3.IconWrapper>
                            <Icon
                                glyph={state.expandPeriod ? 'DropdownUp' : 'DropdownDown'}
                                height={15}
                                width={15}
                            />
                        </Style3.IconWrapper>
                    </Style3.Button>
                </HeaderSection>
                {state.expandPeriod && (
                    <AnalysisSelectionStyle.Wrapper highlightedFilerValue={false}>
                        <AnalysisSelectionStyle.Label>Report Period:</AnalysisSelectionStyle.Label>
                        <AnalysisSelectionStyle.Select
                            value={reportLevelPeriod}
                            onChange={handleOnPeriodChange}
                        >
                            {periodOptions.map((option) => (
                                <option key={option} value={option}>
                                    {getAnalysisPeriodDisplayName(option)}
                                </option>
                            ))}
                        </AnalysisSelectionStyle.Select>
                    </AnalysisSelectionStyle.Wrapper>
                )}
            </Style2.SectionContent>
            <VesselFilters pageType={PageType.METRICS} />
        </Filter>
    )
}
