import styled from 'styled-components'
import {Button} from '../../../../../components/base/button'

export const PillButton = styled(Button).attrs<{selected?: boolean}>((props) => ({
    variant: props.selected ? 'outlined' : 'icon',
    size: 'lg',
}))<{selected?: boolean}>`
    font-weight: 600;
    border-radius: 28px;
`
