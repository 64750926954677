import {ActionButton} from '../../../../components/asset-details/action-button/action-button'
import isUserAllowedToEditAssetReselector from '../../../../components/asset-details/re-reselectors/is-user-allowed-to-edit-asset-reselector'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {GuidType} from '../../../../values/generic-type-defintions'
import * as Styles from './asset-display-info.styled'
import EDIT_BUTTON from '../../../../@assets/icons/edit-pen.svg'
import {addQueryParam} from '../../../../helpers/navigation'
import {FormattedAnomaly} from '../../context/types/formatted-anomaly'
import {assetDisplayDetailsCachedReselector} from '../../context/reselector/asset-display-details-cached-reselector'

interface Props {
    anomaly: FormattedAnomaly
    bottomPositionOfTippy: boolean
}
export function AssetDisplayInfo({anomaly, bottomPositionOfTippy}: Props): JSX.Element {
    const asset = useTypedSelector((state) =>
        assetDisplayDetailsCachedReselector(state, anomaly.identity),
    )
    const isAllowedToEdit = useTypedSelector(isUserAllowedToEditAssetReselector)
    return (
        <Styles.Container bottomPosition={bottomPositionOfTippy}>
            <Styles.Header>
                <Styles.Titles networkAssetState={anomaly.state}>
                    <Styles.Names>
                        <Styles.AssetName id={`asset-name_${asset.assetId}`}>
                            {asset.displayName ??
                                anomaly.hostname ??
                                anomaly.ipAddress ??
                                anomaly.macDescription}
                        </Styles.AssetName>
                        <Styles.VesselName id={`asset-vessel_${asset.assetId}`}>
                            {asset.vesselName ?? anomaly.location}
                        </Styles.VesselName>
                    </Styles.Names>
                    <Styles.StatusValue id={`asset-value_${asset.assetId}`}>
                        {anomaly.state}
                    </Styles.StatusValue>
                </Styles.Titles>
            </Styles.Header>
            <Styles.Details>
                <Styles.Label>Last Event</Styles.Label>
                <Styles.Value id={`asset-last-event_${asset.assetId}`}>
                    {asset.lastEvent}
                </Styles.Value>
                <Styles.Label>Asset identity</Styles.Label>
                <Styles.Value id={`asset-hostname_${asset.assetId}`}>
                    {asset.hostname ?? anomaly.hostname ?? 'Unknown'}
                </Styles.Value>
                <Styles.Label>IP Address</Styles.Label>
                <Styles.Value id={`asset-ip-address_${asset.assetId}`}>
                    {asset.ipAddress ?? anomaly.ipAddress ?? 'Unknown'}
                </Styles.Value>
                <Styles.Label>MAC Address</Styles.Label>
                <Styles.Value id={`asset-mac-address_${asset.assetId}`}>
                    {asset.macAddress ?? anomaly.macAddress ?? 'Unknown'}
                </Styles.Value>
            </Styles.Details>
            <Styles.Actions>
                <Styles.ValueWrapper>
                    <Styles.ValueCircle value={asset.value} />
                    <Styles.Value id={`asset-value_${asset.assetId}`}>
                        {asset.value} value
                    </Styles.Value>
                </Styles.ValueWrapper>
                {isAllowedToEdit && <EditAssetButton assetIdCard={asset.assetId} />}
            </Styles.Actions>
        </Styles.Container>
    )
}

interface EditAssetButtonProps {
    assetIdCard: GuidType
}
function EditAssetButton({assetIdCard}: EditAssetButtonProps): JSX.Element {
    return (
        <ActionButton
            id={`asset-edit_${assetIdCard}`}
            onClick={() => addQueryParam('editAsset', assetIdCard)}
        >
            <img src={EDIT_BUTTON} />
            <Styles.DetailText>Edit</Styles.DetailText>
        </ActionButton>
    )
}
