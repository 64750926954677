import {NetworkAssetModalOutputModel} from '../../../../models/network-asset-modal.model'
import * as Styled from './vessel-status.styled'
import {getImageForNetworkAssetState} from '../../../../context/helpers'
import {StringUtils} from '../../../../../../utils/Utils'
import {VesselTag} from '../../../../../../components/vessel-tag/vessel-tag'
import {DataCell} from '../shared/data-cell'

interface VesselStatusProps {
    model: NetworkAssetModalOutputModel
}

export function VesselStatus({model}: VesselStatusProps): JSX.Element {
    return (
        <Styled.VesselStatusAreaWrapper id="network-asset-details-modal_vessel-status-area_wrapper">
            <Styled.VesselStatusAreaContent id="network-asset-details-modal_vessel-status-area_content">
                <DataCell label="Status:">
                    {StringUtils.capitaliseString(model.state)}{' '}
                    <img src={getImageForNetworkAssetState(model.state)} />
                </DataCell>
                <DataCell label="Asset identity:">
                    {StringUtils.capitaliseString(model.hostname)}
                </DataCell>
                <DataCell label="IP address:">{model.ipAddress}</DataCell>
                <DataCell label="Vessel:">{model.vessel ?? 'Unknown'}</DataCell>
            </Styled.VesselStatusAreaContent>
            <Styled.TagItemsWrapper id="network-asset-details-modal_vessel-status-area_tags">
                {model.vesselTags?.map((tag, index) => (
                    <VesselTag key={index} tagName={tag.name} showHover={true} />
                ))}
            </Styled.TagItemsWrapper>
        </Styled.VesselStatusAreaWrapper>
    )
}
