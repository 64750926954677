import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from 'recharts'
import {AssetTrend, TrendCategory} from './types/metric-report-types'
import {parseISO, startOfDay} from 'date-fns'
import {CrewBehaviourColor, MaintenanceProcessColor, ProtectiveControlColor} from './common.styled'
import {formatDateWithoutTime} from '../../../../helpers/formatting'
import {TimestampFilterType} from '../../../../store/state/metrics-filter-beta/state'

interface AggregatedEntry {
    timestamp: number
    behaviourTotalAssetOk: number
    behaviourTotalAssetWithIssue: number
    behaviourTotalAssetOkPercentage: number | null

    maintenanceTotalAssetOk: number
    maintenanceTotalAssetWithIssue: number
    maintenanceTotalAssetOkPercentage: number | null

    protectionTotalAssetOk: number
    protectionTotalAssetWithIssue: number
    protectionTotalAssetOkPercentage: number | null
}

function aggregateAssetTrend(assetTrend: AssetTrend): AggregatedEntry[] {
    const aggregatedData: {[key: string]: AggregatedEntry} = {}

    const processEntries = (
        entries: TrendCategory[],
        type: 'behaviour' | 'maintenance' | 'protection',
    ) => {
        entries.forEach((entry) => {
            const date = startOfDay(parseISO(entry.timestamp)).toISOString()

            if (!aggregatedData[date]) {
                aggregatedData[date] = {
                    timestamp: new Date(entry.timestamp).getTime(),
                    behaviourTotalAssetOk: 0,
                    behaviourTotalAssetWithIssue: 0,
                    behaviourTotalAssetOkPercentage: null,

                    maintenanceTotalAssetOk: 0,
                    maintenanceTotalAssetWithIssue: 0,
                    maintenanceTotalAssetOkPercentage: null,

                    protectionTotalAssetOk: 0,
                    protectionTotalAssetWithIssue: 0,
                    protectionTotalAssetOkPercentage: null,
                }
            }

            aggregatedData[date][`${type}TotalAssetOk`] += entry.totalAssetsOk
            aggregatedData[date][`${type}TotalAssetWithIssue`] += entry.totalAssetsWithIssue

            let percentageOk = null

            const assetOk = aggregatedData[date][`${type}TotalAssetOk`]
            const assetWithIssue = aggregatedData[date][`${type}TotalAssetWithIssue`]
            const assetTotal = assetOk + assetWithIssue

            if (assetTotal > 0) {
                percentageOk = Math.round((assetOk / assetTotal) * 100)
            }

            aggregatedData[date][`${type}TotalAssetOkPercentage`] = percentageOk
        })
    }

    /* Each key could be null if there is no data in 1 year bucket. */
    processEntries(assetTrend.behaviour ?? [], 'behaviour')
    processEntries(assetTrend.maintenance ?? [], 'maintenance')
    processEntries(assetTrend.protection ?? [], 'protection')

    return Object.values(aggregatedData).sort((a, b) => a.timestamp - b.timestamp)
}

function timestampFilterToDays(timestamp: TimestampFilterType): number {
    switch (timestamp) {
        case TimestampFilterType.LAST_7_DAYS:
            return 7
        case TimestampFilterType.ONE_FORTNIGHT:
            return 14
        case TimestampFilterType.LAST_30_DAYS:
            return 30
        case TimestampFilterType.ONE_QUARTER:
            return 90
        case TimestampFilterType.ONE_YEAR:
            return 365
        default:
            throw new Error('Unknown timestamp filter type')
    }
}

interface Props {
    assetTrend: AssetTrend
    reportLevelPeriod: TimestampFilterType
}

export function AssetTrendChart(props: Props) {
    const aggregatedData = aggregateAssetTrend(props.assetTrend)

    const numberOfDays = timestampFilterToDays(props.reportLevelPeriod)

    const DOT_WIDTH = 5
    const STROKE_OPACITY = 1
    const STROKE_WIDTH = 2

    const endDate = new Date()
    const startDate = new Date()
    startDate.setDate(endDate.getDate() - numberOfDays)

    return (
        <ResponsiveContainer minHeight={200}>
            <LineChart data={aggregatedData} margin={{top: 10, right: 10, left: 10, bottom: 10}}>
                <CartesianGrid
                    stroke="#000000"
                    strokeOpacity={1}
                    strokeWidth="0.2"
                    vertical={false}
                    syncWithTicks={true}
                />
                <XAxis
                    dataKey="timestamp"
                    type="number"
                    tickLine={false}
                    tick={false}
                    domain={[startDate.getTime(), endDate.getTime()]}
                    tickFormatter={(value) => new Date(value).toISOString().slice(0, 10)}
                />
                <YAxis
                    label={{value: '# of assets ok', angle: '-90', fill: 'black', dx: -30}}
                    ticks={[25, 50, 75, 100]}
                    axisLine={false}
                    tickLine={false}
                    interval={0}
                    tickFormatter={(value) => `${value}%`}
                />
                <Tooltip
                    labelFormatter={(value) => formatDateWithoutTime(new Date(value).toISOString())}
                    formatter={(value, name) => {
                        if (name === 'protectionTotalAssetOkPercentage') {
                            return [`${value}%`, 'Protection']
                        } else if (name === 'maintenanceTotalAssetOkPercentage') {
                            return [`${value}%`, 'Maintenance']
                        } else if (name === 'behaviourTotalAssetOkPercentage') {
                            return [`${value}%`, 'Behaviour']
                        } else {
                            return [`${value}%`, 'Unknown']
                        }
                    }}
                />
                <Legend
                    iconType="square"
                    formatter={(val) => {
                        if (val === 'protectionTotalAssetOkPercentage') {
                            return 'Protection'
                        } else if (val === 'maintenanceTotalAssetOkPercentage') {
                            return 'Maintenance'
                        } else if (val === 'behaviourTotalAssetOkPercentage') {
                            return 'Behaviour'
                        } else {
                            return 'Unknown'
                        }
                    }}
                />
                <Line
                    type="linear"
                    dot={{fill: ProtectiveControlColor, strokeWidth: DOT_WIDTH}}
                    dataKey="protectionTotalAssetOkPercentage"
                    stroke={ProtectiveControlColor}
                    strokeOpacity={STROKE_OPACITY}
                    strokeWidth={STROKE_WIDTH}
                    connectNulls
                />
                <Line
                    type="linear"
                    dot={{fill: MaintenanceProcessColor, strokeWidth: DOT_WIDTH}}
                    dataKey="maintenanceTotalAssetOkPercentage"
                    stroke={MaintenanceProcessColor}
                    strokeOpacity={STROKE_OPACITY}
                    strokeWidth={STROKE_WIDTH}
                    connectNulls
                />
                <Line
                    type="linear"
                    dot={{fill: CrewBehaviourColor, strokeWidth: DOT_WIDTH}}
                    dataKey="behaviourTotalAssetOkPercentage"
                    stroke={CrewBehaviourColor}
                    strokeOpacity={STROKE_OPACITY}
                    strokeWidth={STROKE_WIDTH}
                    connectNulls
                />
            </LineChart>
        </ResponsiveContainer>
    )
}
