import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {FormattedAnomaly} from '../../context/types/formatted-anomaly'
import {getUpdatedDisplayValue} from '../helpers/data-helpers'
import {CardDataCell} from './card-data-cell'
import * as Styled from './_styled/unknown-assets-card.styled'
import {getImageForNetworkAssetState} from '../../context/helpers'
import {usePagedUnknownAssetsBeta} from '../../context/use-paged-unknown-assets'
import {ToggleDetailsButton} from './details/toggle-details-button'
import {ToggleButtonCell} from './details/toggle-button-cell'
import {NetworkAssetDetails} from './_styled/network-asset-details.styled'
import {NetworkAssetDetailsExpanded} from './network-asset-details-extended/network-asset-details-extended'
import {TrustDeviceCellCard} from './trust-device-cell-card'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {acEnabledSelector} from '../../../../store/state/config/selectors'
import {TrustDeviceCellCardNoAc} from './trust-device-cell-card-no-ac'

interface UnknownAssetsCardIn3ColProps {
    anomaly: FormattedAnomaly
}

export function UnknownAssetsCard({anomaly}: UnknownAssetsCardIn3ColProps): JSX.Element {
    const {width} = useDimensions()
    const {networkAssetModalId} = usePagedUnknownAssetsBeta()
    const acEnabled = useTypedSelector(acEnabledSelector)

    const detailExtended = networkAssetModalId === anomaly.identity
    const activeNetworkAsset = anomaly.identity === networkAssetModalId

    return (
        <NetworkAssetDetails
            activeNetworkAsset={activeNetworkAsset}
            id={`NetworkAssetDetails_${anomaly.identity}`}
        >
            <Styled.Card width={width} acEnabled id={`data-card_${anomaly.identity}`}>
                <Styled.CardAssetTitleData
                    gridArea="ASSET-TITLE"
                    id={`network-assets-CardAssetTitleData_${anomaly.identity}`}
                >
                    <img
                        src={getImageForNetworkAssetState(anomaly.state)}
                        style={{marginRight: '5px'}}
                    />
                    <div id={`network-assets-mac-description_${anomaly.identity}`}>
                        {anomaly.macDescription}
                    </div>
                </Styled.CardAssetTitleData>
                <Styled.CardLocationTitleData
                    gridArea="LOCATION-TITLE"
                    id={`network-assets-CardLocationTitleData_${anomaly.identity}`}
                >
                    <div id={`network-assets-vessel_${anomaly.identity}`}>{anomaly.location}</div>
                </Styled.CardLocationTitleData>
                <CardDataCell
                    gridArea="WHEN"
                    label="First Detected:"
                    anomaly={anomaly}
                    identifier="first_detected"
                >
                    {anomaly.occurred}
                </CardDataCell>
                <CardDataCell
                    gridArea="IP-ADDRESS"
                    label="IP Address:"
                    anomaly={anomaly}
                    identifier="ip-address"
                >
                    {anomaly.ipAddress}
                </CardDataCell>
                <CardDataCell
                    gridArea="NETWORK"
                    label="Network:"
                    anomaly={anomaly}
                    identifier="network"
                >
                    {anomaly.network}
                </CardDataCell>
                <CardDataCell
                    gridArea="UPDATED-BY"
                    label="Updated:"
                    anomaly={anomaly}
                    identifier="updated-by"
                >
                    {getUpdatedDisplayValue(anomaly.isolationStatus.updatedAt)}
                </CardDataCell>
                <CardDataCell
                    gridArea="HOSTNAME"
                    label="Asset identity:"
                    anomaly={anomaly}
                    identifier="asset-identity"
                >
                    {anomaly.hostname}
                </CardDataCell>
                {acEnabled ? (
                    <TrustDeviceCellCard anomaly={anomaly} />
                ) : (
                    <TrustDeviceCellCardNoAc anomaly={anomaly} />
                )}
                <ToggleButtonCell gridArea="VIEW-DETAIL">
                    <ToggleDetailsButton id={anomaly.identity} />
                </ToggleButtonCell>
            </Styled.Card>
            {detailExtended && <NetworkAssetDetailsExpanded />}
        </NetworkAssetDetails>
    )
}
