import {LocationIdType} from '../../../../../store/state/locations/state'
import {MetricsResponseForAssetStatus, MetricType} from '../../../contexts/types/metrics-response'
import {ServerPagedResponse} from '../table/server-paged-table.types'
export type {ServerPagedResponse} from '../table/server-paged-table.types'

export interface AssetStatusCategory {
    totalAssetsOk: number
    totalAssetsWithIssue: number
}

export interface PerformanceCategory {
    aboveTarget: number
    belowTarget: number
}

export interface TrendCategory {
    // ISO datetime
    timestamp: string
    totalAssetsOk: number
    totalAssetsWithIssue: number
}

export interface AssetStatus {
    behaviour: AssetStatusCategory
    maintenance: AssetStatusCategory
    protection: AssetStatusCategory
}

export interface Performance {
    behaviour: PerformanceCategory
    maintenance: PerformanceCategory
    protection: PerformanceCategory
}

export type IssuesRaised = {
    [key in MetricType]: AssetStatusCategory
}

// LocationPerformance is AssetStatusCategory with additional
// totalAssetsOkPercentage and locationId field.
export interface LocationPerformance extends AssetStatusCategory {
    // totalAssetsOkPercentage is the percentage of OK asset over total asset for a location.
    totalAssetsOkPercentage: number
    locationId: LocationIdType
}

export interface VesselMetrics {
    locationId: string
    malwareDetection: AssetStatusCategory
    accessControls: AssetStatusCategory
    securityTools: AssetStatusCategory
    removableControls: AssetStatusCategory
    privilegedAccounts: AssetStatusCategory
    osSupported: AssetStatusCategory
    assetOnline: AssetStatusCategory
    malwareDefinition: AssetStatusCategory
    osSecurityPatches: AssetStatusCategory
    usbStorage: AssetStatusCategory
    mobileDevices: AssetStatusCategory
    hotspotMode: AssetStatusCategory
    wirelessConnection: AssetStatusCategory
    portableSoftware: AssetStatusCategory
    adminLogons: AssetStatusCategory
}

export interface VesselPerformanceWithMetrics {
    topN: VesselMetrics[]
    bottomN: VesselMetrics[]
}
export interface VesselPerformance {
    topN: LocationPerformance[]
    bottomN?: LocationPerformance[]
}

export interface IssueBreakdownByTags {
    tagName: string
    behaviour: number
    maintenance: number
    protection: number
    totalIssue?: number
}

export interface AssetTrend {
    behaviour: TrendCategory[]
    maintenance: TrendCategory[]
    protection: TrendCategory[]
}

export interface Summary {
    assetStatus: AssetStatus
    performance: Performance
    issuesRaised: IssuesRaised
    vesselPerformance: VesselPerformance
    issueBreakdownByTags: IssueBreakdownByTags[]
    assetTrend: AssetTrend
    vesselPerformanceWithMetrics: VesselPerformanceWithMetrics
    mostImprovedAndMostDeterioratedMetrics: MetricTrend[]
}

export interface TrendPointWithPctOkAndChange extends TrendCategory {
    change: number
    totalAssetsOkPercentage: number
}

export interface MetricTrend {
    trend: TrendPointWithPctOkAndChange[]
    metricType: MetricType
}

export interface MetricsReportSummaryResponse {
    summary: Summary | null
}

export type MetricsReportTableResponse = ServerPagedResponse<MetricsResponseForAssetStatus>

export enum IssueBreakdownByTagsTableHeader {
    TAG_NAME = 'tagName',
    TOTAL_ISSUE = 'totalIssue',
    BEHAVIOUR = 'behaviour',
    PROTECTION = 'protection',
    MAINTENANCE = 'maintenance',
}

export enum DetailedViewTableHeader {
    LOCATION_NAME = 'locationName',
    ASSET_NAME = 'assetName',
    FRAMEWORK = 'framework',
    METRIC = 'metric',
    ISSUE_DETECTED_DAYS = 'issueDetectedDays',
}

export interface SortBy {
    isAsc: boolean
    colIdx: number
}

export const MetricTypeToLabelLookup: {[key: string]: string} = {
    [MetricType.MALWARE_DEFINITION]: 'Malware Definition',
    [MetricType.OS_SECURITY_PATCHES]: 'OS Security Patches',
    [MetricType.ASSET_ONLINE]: 'Asset Online',
    [MetricType.MALWARE_DETECTION]: 'Malware Detection',
    [MetricType.SUPPORTED_OS]: 'Supported OS',
    [MetricType.PRIVILEGED_ACCOUNTS]: 'Privileged Accounts',
    [MetricType.ACCESS_CONTROLS]: 'Access Controls',
    [MetricType.REMOVABLE_CONTROLS]: 'Removable Controls',
    [MetricType.SECURITY_TOOLS]: 'Security Tools',
    [MetricType.ADMIN_LOGON]: 'Admin Logon',
    [MetricType.HOTSPOT_MODE]: 'Hotspot Mode',
    [MetricType.MOBILE_DEVICES]: 'Mobile Devices',
    [MetricType.PORTABLE_SOFTWARE]: 'Portable Software',
    [MetricType.USB_STORAGE]: 'USB Storage',
    [MetricType.WIRELESS_CONNECTIVITY]: 'Wireless Connectivity',
}
