import {useState} from 'react'
import * as Styled from './score-bar.styled'
import {LocationPerformance} from './types/metric-report-types'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {locationsSelector} from '../../../../store/state/locations/selectors'

interface ScoreBarProps {
    locationPerformance: LocationPerformance
    displayGraph: boolean
}
export function ScoreBar({locationPerformance, displayGraph}: ScoreBarProps): JSX.Element {
    const locations = useTypedSelector(locationsSelector)

    const [visibleTippy, setVisibleTippy] = useState(false)
    const {totalAssetsOkPercentage, locationId, totalAssetsOk, totalAssetsWithIssue} =
        locationPerformance
    const details = locations.find((i) => i.location === locationId)
    const description = details ? details.description : locationId

    return (
        <>
            {displayGraph && (
                <Styled.ScoreBarWrapper id={`scoreBar-Wrapper-${description}`}>
                    <Styled.DescriptionWrapper id={`color-Wrapper-${description}`}>
                        <Styled.Description id={`score-${description}`}>
                            {description}
                        </Styled.Description>
                    </Styled.DescriptionWrapper>
                    <Styled.ScoreBar
                        onMouseEnter={() => {
                            setVisibleTippy(true)
                        }}
                        onMouseLeave={() => {
                            setVisibleTippy(false)
                        }}
                    >
                        <Styled.ScoreBarNoIssue
                            score={totalAssetsOkPercentage}
                            id={`score-bar-no-issues-${description}`}
                        />
                        {visibleTippy && (
                            <TippyContent
                                locationPerformance={locationPerformance}
                                description={description}
                            />
                        )}
                    </Styled.ScoreBar>
                    <Styled.ScoreBarSpan>
                        {totalAssetsOkPercentage.toFixed(2)}% ({totalAssetsOk} of{' '}
                        {totalAssetsOk + totalAssetsWithIssue} asset(s) OK)
                    </Styled.ScoreBarSpan>
                </Styled.ScoreBarWrapper>
            )}
        </>
    )
}

interface TippyContentProps {
    description: string
    locationPerformance: LocationPerformance
}

function TippyContent({description, locationPerformance}: TippyContentProps): JSX.Element {
    return (
        <Styled.TippyWrapper>
            <div style={{display: 'flex', gap: '5px', fontWeight: 'bold'}}>{description}</div>
            <div>Assets OK: {locationPerformance.totalAssetsOkPercentage.toFixed(2)}%</div>
        </Styled.TippyWrapper>
    )
}
