import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {NoDataContent} from '../../../../dashboard-v2/components/general/content-area/no-data/no-data-content'
import {TableColValue} from './server-paged-table.types'
import * as Styled from './tags-table-body.styled'

export function TableContent({rows}: {rows: TableColValue[][]}): JSX.Element {
    const {width} = useDimensions()
    if (Object.keys(rows).length === 0) {
        return (
            <div style={{padding: '30px'}}>
                <NoDataContent text="No data" />
            </div>
        )
    }

    return (
        <div>
            <Styled.TableDataGrid width={width} showReducedGrid={false}>
                {rows.map((row, i) => (
                    <TableRow key={i} index={i} data={row} />
                ))}
            </Styled.TableDataGrid>
        </div>
    )
}

type TableRowProps = {
    index: number
    data: TableColValue[]
}

function TableRow({index, data}: TableRowProps): JSX.Element {
    const gridRow = index + 1

    return (
        <>
            <Styled.TableDataRow gridRow={gridRow} id={`data-row-${data[0]}`} />
            {data.map((r, i) => (
                <Styled.TableDataCell gridColumn={i + 2} gridRow={gridRow} key={i} centered={false}>
                    {r}
                </Styled.TableDataCell>
            ))}
        </>
    )
}
