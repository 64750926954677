import styled from 'styled-components'
import {LoadingSpinner} from '../../../../../components/loading/loading'

const AbsoluteDiv = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
`

export function LoaderOverlay({isLoading}: {isLoading: boolean}): JSX.Element {
    return (
        <>
            {isLoading && (
                <AbsoluteDiv>
                    <LoadingSpinner size={64} />
                </AbsoluteDiv>
            )}
        </>
    )
}
